.drag {
  cursor: grab;
}

.node-action-button {
  width: 24px;
  height: 24px;
  color: rgb(80, 80, 80);
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
}

.node-action-button:hover {
  width: 24px;
  height: 24px;
  color: rgb(0, 0, 0)
}

.react-flow-container {
  height: calc(100vh - 180px);

  border: 1px solid black;
  /* height of toolbar */
}

.node {
  width: 100%;
  height: 100%;
}

.node-header {
  background: #6060605c;
  font-size: 1rem;
  height: 1.6rem;
  border-radius: 5px 5px 0 0;
  margin-bottom: 0.2rem;
}

.node-footer {
  display: flex;
  justify-content: center;
  background: #6060605c;
  font-size: 0.5rem;
  border-radius: 0 0 5px 5px;
  margin-bottom: 0;
}

.add-node-button {
  background: #57b64c;
  border: 1px solid gray;
  border-radius: 2px;
  padding: 2px;
  width: 15ch;
}