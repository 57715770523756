body {
  margin:0;
  color: #000;
  font-family: 'Manrope',sans-serif;
  letter-spacing: .015em;
  font-size: 1.0rem;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
  background-color: #f7f7f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



a {
  text-decoration: none; 
  outline: none;
  color: #535353;
  word-break: break-word;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  border-bottom: 1px solid transparent;  
  cursor: pointer;
}

.btn-link {
    text-decoration: none;
    outline: none;
    color: #18a287;
    font-weight: 600;
    word-break: break-word;
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    border-bottom: 1px solid transparent;  
    cursor: pointer;
}

.btn-link:hover{
  font-weight: 600;
  color: #18a287;
  border-bottom: 1px solid #18a287;;
  
}


.uccb2c-h1 {
  text-decoration: none;
  outline: none;
  color: #18a287;
  font-weight: 800;
  word-break: break-word;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  border-bottom: 1px solid transparent;  
  font-size:1.2rem;
}

.uccb2c-h2 {
  text-decoration: none;
  outline: none;
  color: #18a287;
  font-weight: 800;
  word-break: break-word;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  border-bottom: 1px solid transparent;  
  font-size:0.8rem;
}





.pull-left{
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: flex-start;
  justify-content: flex-start;
}

.pull-right{
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: flex-end;
  justify-content: flex-end;
}



.b2-heading-xxl{
    margin: 0;
    color: #9f2943;
    font-size: 1.75rem;
}
.b2-heading-xl{
  margin: 0;
  color: #9f2943;
  font-size: 1.4rem;
}

.b2-heading-l{
  margin: 0;
  color: #9f2943;
  font-size: 1.2rem;
}

.b2-heading-md{
  margin: 0;
  color: #9f2943;
  font-size: 1.0rem;
}

.b2-heading-xs{
  margin: 0;
  color: #9f2943;
  font-size: 0.8rem;
}




.icon-button{
  width: 16px;
  height: 16px;
  color: rgb(89, 89, 89);
  margin: 2px;
  cursor: pointer;
  
}

.icon-button-primary{
  color: rgb(138, 138, 234)
}
.icon-button-danger{
  color: red;
}

.icon-button:hover{
  /* width: 26px;
  height: 26px; */
  color: rgb(32, 32, 32);
}