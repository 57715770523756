.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 0 14px #00000042;
}

.site-header{
  display: flex;
  background: #9f2943;
  height: 2rem;
  padding-left: 1.5rem;
  align-items: center
}

.area-navigation {
  display: flex;
  align-items: baseline;
}

.area-navigation a {
  border: none;
  color: #eee;
  font-size: .875rem;
  padding: 5px 16px;
  text-decoration: none;
  text-decoration-line: none;
  font-weight: 600;
}

.area-navigation a:hover{
  color: #fff;
}

a:visited{
  text-decoration: none;
}

.site-navigation{
  display: flex;
  flex-direction: column;
  height: 4rem;
  background: white;
  justify-content: center;
  padding-left: 1.5rem;
}

.site-navigation-row{
  height: auto;
}

.bredband2-logo-svg{
  max-height: 2rem;
  max-width: 600px;
  margin: 5px;  
}
